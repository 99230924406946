
import { Component, Vue } from "vue-property-decorator";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { api } from "@/api";
import { appName, supersetUrl } from "@/env";
import axios from "axios";
import { table } from "console";
import { readWorkspace, readToken } from "@/store/main/getters";


@Component
export default class Embedder extends Vue {

  public embeddedId: number = -1;
  public embeddedDashboard: any = null;
  // Move this to backend i guess?
  public async loginAndGetAccessToken(): Promise<string> {
    try {
      const response = await api.getSupersetToken(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10)
      );
      return response.data.access_token;

    } catch (error) {
      console.error('Error logging in:', error);
      throw new Error('Failed to log in');
    }
  }

  get token() {
    return readToken(this.$store);
  }

  public async getEmbeddedDashboard() {
    try {
      const response = await api.getEmbeddedDashboard(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.embeddedId
      );
      return response.data;
    } catch (error) {
      console.error('Error getting embedded dashboard:', error);
      throw new Error('Failed to get embedded dashboard');
    }
  }

  public async mounted() {

    if (this.$router.currentRoute.params.embedded_id !== undefined) {
      this.embeddedId = parseInt(this.$router.currentRoute.params.embedded_id, 10);
    }

    this.embeddedDashboard = await this.getEmbeddedDashboard();

    const r = await this.loginAndGetAccessToken();

    const mountPoint = document.getElementById("my-superset-container");

    if (mountPoint) {
      // Proceed with using mountPoint as HTMLElement
      embedDashboard({
          id: this.embeddedDashboard.sdk_id, // given by the Superset embedding UI
          supersetDomain: supersetUrl,
          mountPoint: mountPoint, // any html element that can contain an iframe
          fetchGuestToken: () => this.generateGuestToken(r),
          dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional), urlParams (optional)
            hideTitle: true,
            filters: {
                expanded: false,
            },
            hideChartControls: false,
            urlParams: {

                // t_name: 'z_2',
                // d_id: 2,
                // ...
            }
  },
      });
    } else {
      // Handle the case where the element is not found
      console.error("Element with ID 'my-superset-container' not found.");
    }
  }

  public async generateGuestToken(accessToken: string): Promise<string> {
    try {
      const response = await axios.post(
        `${supersetUrl}/api/v1/security/guest_token/`,
        {
          resources: [
            {
              type: 'dashboard',
              id: this.embeddedDashboard.sdk_id // Replace with the actual dashboard ID
            }
          ],
          rls: [
          ],
          user: {
            username: 'guest_user' // Specify the guest user
          },
        },
        
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,          },
        }
      );

      return response.data.token;
    } catch (error) {
      console.error('Error generating guest token:', error);
      throw new Error('Failed to generate guest token');
    }
  }

}
