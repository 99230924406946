
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import { IGenerativeModel } from "@/interfaces";
import { readGenerativeModels } from "@/store/generative_model/getters";
import {
  dispatchGetGenerativeModels,
  dispatchCreateGenerativeModel,
  dispatchDeleteGenerativeModel,
  dispatchResetGenerativeModel,
} from "@/store/generative_model/actions";
import Fuse from "fuse.js";
import { readUserProfile, readActiveWorkspace } from "@/store/main/getters";
import { dispatchUpdateUserSettings } from "@/store/main/actions";
import { readWorkspace } from "@/store/main/getters";

import FileDrop from "@/components/FileDrop.vue";
import ModelPageCard from "@/components/ModelPageCard.vue";

@Component({
  components: {
    FileDrop,
    ModelPageCard,
  },
})
export default class ModelView extends Vue {
  public dialog: boolean = false;
  public dialogRemove: boolean = false;
  public loading: boolean = false;
  public removeModelId: number = 0;
  public resetModelId: number = 0;
  public intervalId: number | null = null;
  public updatingSelectedColors: boolean = false;
  public selectedColors: any[] = [];
  public modelColors: any[] = [];
  public privateModels: IGenerativeModel[] = [];
  public sortOption: string = "dateDesc";
  public searchQuery: string = "";
  public fuseOptions: any = {
    keys: ["name", "description"], // The fields to search in
    includeScore: true, // Include search match score
    threshold: 0.4, // Lower value means stricter matching
  };
  public fuse: any = new Fuse([], this.fuseOptions);
  public newKey: number = 0;

  @Watch("selectedColors")
  onSelectedColorsChange(newSelectedColors: string[], oldSelectedColors: string[]) {
    if (this.updatingSelectedColors) {
      this.updatingSelectedColors = false;
      return;
    }

    const allColors = this.modelColors.map((c) => c.value);

    // Handle the case when selectedColors becomes empty
    if (newSelectedColors.length === 0) {
      // User tried to unselect all colors
      this.updatingSelectedColors = true;
      this.selectedColors = allColors; // Reset to select all colors

      dispatchUpdateUserSettings(this.$store, {
        settings: {
          generative_page: {
            color_preference: this.selectedColors,
          },
        },
      });
      this.setPrivateModels();

      return;
    }

    const wasAllSelected = oldSelectedColors.length === allColors.length;
    const isNowLessThanAll = newSelectedColors.length === allColors.length - 1;

    if (wasAllSelected && isNowLessThanAll) {
      const removedColors = oldSelectedColors.filter((color) => !newSelectedColors.includes(color));

      if (removedColors.length === 1) {
        const clickedColor = removedColors[0];

        this.updatingSelectedColors = true;
        this.selectedColors = [clickedColor];

        this.setPrivateModels();
        dispatchUpdateUserSettings(this.$store, {
          settings: {
            generative_page: {
              color_preference: this.selectedColors,
            },
          },
        });

        return;
      }
    }
    dispatchUpdateUserSettings(this.$store, {
      settings: {
        generative_page: {
          color_preference: this.selectedColors,
        },
      },
    });
    // We also want to update the user settings.
    this.setPrivateModels();
  }

  public setModelColors() {
    let colors: any[] = [];

    this.models.forEach((m) => {
      if (m.color !== "black" || this.userProfile!.is_superuser) {
        colors.push(m.color);
      }
    });
    const uniqueColors = new Set(colors);
    const colorArray = Array.from(uniqueColors);
    const colorArrayWithNames = colorArray.map((color) => {
      if (color === null) {
        return {
          name: "No color",
          value: null,
        };
      }
      return {
        name: color,
        value: color,
      };
    });
    this.modelColors = colorArrayWithNames;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public unpackPreferences() {
    this.updatingSelectedColors = true;
    this.setModelColors();
    // Assert that settings is a Record<string, any>
    const settings = this.userProfile?.settings as Record<string, any> | undefined;
    console.log("settings", settings);
    if (
      settings &&
      "generative_page" in settings &&
      "color_preference" in settings.generative_page
    ) {
      //Check if the color is in this.modelColors

      const colorPreference = settings.generative_page.color_preference;
      const validColors = this.modelColors.map((c) => c.value);
      const filteredColors = colorPreference.filter((c) => validColors.includes(c));

      // Assign the user's color preference to selectedColors if the color is in validColors
      if (filteredColors.length > 0) {
        this.selectedColors = filteredColors;
      } else {
        // Default to all colors if no preference is set
        this.selectedColors = this.modelColors.map((c) => c.value);
      }
    } else {
      // Default to all colors if no preference is set
      this.selectedColors = this.modelColors.map((c) => c.value);
    }
  }

  public async mounted() {
    await this.fetchModels();

    this.unpackPreferences();

    this.intervalId = window.setInterval(() => {
      this.fetchModels();
      this.newKey += 1;
      this.setPrivateModels();
    }, 60000);

    this.setPrivateModels();
  }
  // await this.fetchModels();
  // this.intervalId = window.setInterval(() => {
  //   this.fetchModels();
  //   this.newKey += 1;
  //   this.setPrivateModels();

  // }, 15000);
  // this.setModelColors();
  // this.selectedColors = this.modelColors.map((c) => c.value);
  // this.setPrivateModels();
  // }

  public beforeRouteLeave(to: any, from: any, next: any) {
    if (this.intervalId !== null) {
      window.clearInterval(this.intervalId);
    }
    next();
  }

  public async fetchModels() {
    await dispatchGetGenerativeModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    this.fuse = new Fuse(this.models, this.fuseOptions);
  }

  public beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate in modeolsviewgenerative");
    dispatchGetGenerativeModels(this.$store, {
      id: +to.params.workspaceid,
    });
    next();
  }

  get isClassification() {
    return this.$router.currentRoute.path.includes("classification");
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  get models() {
    console.log(readGenerativeModels(this.$store));
    return readGenerativeModels(this.$store);
  }

  public removeModel(id, dialogRemove) {
    this.dialogRemove = dialogRemove;
    this.removeModelId = id;
  }

  public async deleteModel() {
    this.loading = true;
    console.log("removing model");
    await dispatchDeleteGenerativeModel(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: this.removeModelId,
    })
      .then((r) => {
        this.removeModelId = 0;
        this.dialogRemove = false;
        this.loading = false;
        this.setPrivateModels();
      })
      .catch((r) => {
        this.loading = false;
        this.dialogRemove = false;
        this.removeModelId = 0;
      });
  }

  public changeSort(sortOption: string) {
    if (sortOption !== this.sortOption) {
      this.sortOption = sortOption;
      this.setPrivateModels();
    }
  }

  public setPrivateModels() {
    // Filter out public models
    this.privateModels = [];
    const pModels = this.models.filter((dataset) => !dataset.public);

    // Sort based on the sortOption
    let sortedPModels: any[];
    if (this.sortOption === "dateDesc") {
      sortedPModels = pModels.slice().sort((a, b) => b.id - a.id);
    } else if (this.sortOption === "colorAsc") {
      sortedPModels = pModels.slice().sort((a, b) => {
        const colorA = a.color || "";
        const colorB = b.color || "";

        if (colorA === colorB) {
          return b.id - a.id; // Sort by id within the same color
        }
        return colorA.localeCompare(colorB); // Sort by color
      });
    } else {
      sortedPModels = pModels;
    }

    // Filter by selected colors
    const colorFiltered = sortedPModels.filter((m) => this.selectedColors.includes(m.color));
    // If a search query is provided, use Fuse.js to search
    let searchFiltered = colorFiltered;
    if (this.searchQuery && this.searchQuery.trim() !== "") {
      const fuseResults = this.fuse.search(this.searchQuery);
      const resultModels = fuseResults.map((result) => result.item);

      // Filter the colorFiltered models using the Fuse search results
      searchFiltered = colorFiltered.filter((model) => resultModels.includes(model));
    }

    // Set the privateModels property with the final filtered results
    this.privateModels = searchFiltered;
  }
}
